import { IResourceComponentsProps, IRouterProvider } from "@pankod/refine-core";
import { useEffect, useState, useRef, useMemo } from "react";

import "./campaign.css";

import {
    List,
    Table,
    Form,
    Space,
    Button,
    SaveButton,
    EditButton,
    Select,
    TagField,
    TextField,
    useEditableTable,
    CreateButton,
    DeleteButton,
    Modal,
    notification,
    Alert,
    Carousel,
    Checkbox,
    Input,
    message,
    Card,
    Tooltip,
    DatePicker,
    Collapse,
    Tag,
    Statistic,
    Row,
    Col,
} from "@pankod/refine-antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import TextareaAutosize from "react-textarea-autosize";

import { IDuplicationDetails, ILead } from "interfaces";

import {
    TABLE_COLUMNS,
    STATUS_OPTIONS,
    AUTOMATED_TRIGGER_BUCKET_STATUS_OPTIONS,
    ORIGIN_OPTIONS,
    UNEDITABLE_COLUMNS,
    BADGE_OPTIONS,
} from "utils/constants";
import CreateNewLeadForm from "components/CreateNewLeadForm";
import ChangeBulk from "components/ChangeBulk";
import { useCampaignData } from "context/campaignData";
import { useSettings } from "context/settings";
import axios from "axios";
import env from "config/site.config";
import type { ColumnsType, ColumnType } from "antd/es/table";
import type { FilterConfirmProps } from "antd/es/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import type { InputRef } from "antd";
import GetEmailButton from "components/GetEmailButton";
import { LoadingOutlined, RedoOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { getUserSettings } from "utils/api";
import React from "react";
import MessageVerification from "components/MessageVerification";
import MoveToCampaign from "components/MoveToCampaign";
import { useNavigate } from "@pankod/refine-react-router-v6";
import moment from "moment-timezone";
import posthog from "posthog-js";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const PostList: React.FC<IResourceComponentsProps> = () => {
    const { campaignId, campaignData } = useCampaignData();
    const { settings } = useSettings();
    const [userSettings, setUserSettings] = useState<any>(null);
    const [totalLeadsCount, setTotalLeadsCount] = useState(0);
    const [scheduledLeadsCount, setScheduledLeadsCount] = useState(0);
    const editable = settings?.editable;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalData, setModalData] = useState<{
        value: string;
        column: string;
        title: string;
        id: number;
        templates: any;
    }>({ value: "", column: "", title: "", id: 0, templates: [] });
    const [expandAll, setExpandAll] = useState(true);
    const [hidePushedApprovedDenied, setHidePushedApprovedDenied] = useState(true);
    const [badgesOptions, setBadgesOptions] = useState<any[]>(BADGE_OPTIONS);
    const [modalTemplates, setModalTemplates] = useState<any[]>([]);
    const [adminBadges, setAdminBadges] = useState<any[]>([]);
    const [checkedRows, setCheckedRows] = useState<{ [key: string]: boolean }>({});
    const [loadingTempNotes, setLoadingTempNotes] = useState<boolean>(false);
    const [duplicateArray, setDuplicateArray] = useState<Set<number>>(new Set());
    const [blackListArray, setBlackListArray] = useState<Set<number>>(new Set());

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInputRef = useRef<InputRef>(null);

    // message modal state
    const [contentInputFill, setContentInputFill] = useState(false);
    const [chosenTemplate, setChosenTemplate] = useState<any>(null);
    const [generatedMessage, setGeneratedMessage] = useState<string>("");
    const [loadingMessageGeneration, setLoadingMessageGeneration] = useState<boolean>(false);

    const [nextPushTime, setNextPushTime] = useState<{ localTime: string; customerTime: string }>({ localTime: "", customerTime: "" });

    const navigate = useNavigate();

    const getAllTemplates = (badges: string, record: any) => {
        const badgeArray = badges.split(",");

        console.log("badgeArray", badgeArray);

        // find the matching badge in the badges in badgesOptions
        const leadsBadges = badgeArray.map((badge) => {
            return badgesOptions.find((option) => option.value === badge.toUpperCase());
        });

        console.log("leadsBadges", leadsBadges);

        // loop through the badges and return the templates intp an array
        let templates: any[] = [];

        leadsBadges?.map((badge) => {
            if (badge?.templates) {
                // add to the templates array
                templates = [...templates, ...badge.templates];
            }
        });

        setModalTemplates(templates);
        return templates;
    };

    const getTotalLeadsCount = async (campaignId: string) => {
        await axios
            // search with a matching search
            .get(`${env.REACT_APP_BACKEND_URL}/api/v1/campaign/count/${campaignId}${searchText && `?${searchedColumn}=${searchText}`}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            })
            .then((res) => {
                if (res?.data[0]?.length === 0) return;
                console.log("count", res.data[0].count);

                setTotalLeadsCount(res.data[0].count);
            });
    };

    const getScheduledLeadsCount = async () => {
        console.log("profileId:", userSettings?.user_id);

        await axios
            .get(`${env.REACT_APP_BACKEND_URL}/api/v1/campaign/count/all?status=4&profile_id=${userSettings.user_id}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            })
            .then((res) => {
                if (res?.data[0]?.length === 0) return;
                console.log("count scheduled", res.data[0].count);

                setScheduledLeadsCount(res.data[0].count);
            });
    };

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
        },
        getCheckboxProps: (record: any) => ({
            disabled: record.name === "Disabled User", // Column configuration not to be checked
            name: record.name,
        }),
    };

    const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText("");
    };

    const getNextPushTime = (profileTimezone: string): { localTime: string; customerTime: string } => {
        const nowInProfileTimezone = moment.tz(profileTimezone);
        let next8AMInProfileTimezone = nowInProfileTimezone.clone().startOf("day").add(8, "hours");
        if (nowInProfileTimezone > next8AMInProfileTimezone) {
            next8AMInProfileTimezone.add(1, "day");
        }

        // Format timezone hour
        const timezoneHour = next8AMInProfileTimezone.format("HH:mm");

        // Use formatted hour and city for the timezone label
        const timezoneLabel = `${timezoneHour}`;

        const formattedString = next8AMInProfileTimezone.toDate().toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            // day: "numeric",
            // month: "short",
            // year: "numeric",
            hour12: false,
        });

        return { localTime: formattedString, customerTime: timezoneLabel };
    };

    useEffect(() => {
        if (!userSettings?.user_id) return;
        getScheduledLeadsCount();
    }, [userSettings?.user_id]);

    useEffect(() => {
        if (userSettings?.timezone) {
            const nextPushTime = getNextPushTime(userSettings.timezone);
            console.log("nextPushTime", nextPushTime);
            setNextPushTime(nextPushTime);
        }
    }, [userSettings?.timezone]);

    const compareForSort = (a: { value: String }, b: { value: String }) => {
        if (a.value.toString() < b.value.toString()) {
            return -1;
        }
        if (a.value.toString() > b.value.toString()) {
            return 1;
        }
        return 1;
    };

    const disabledTriggerWarning = (trigger_name: string) => {
        Modal.warning({
            title: `${trigger_name} trigger is turned off for this profile`,
            // show Remove Trigger and Add anyways button
            okText: "Push anyway",
            okType: "default",
            cancelButtonProps: {
                type: "primary",
            },
            cancelText: "Cancel",
            okCancel: true,
            onCancel: () => {
                setEditId(undefined);
            },
            onOk: () => {},
        });
    };

    const checkIfTriggerIsOn = (record: ILead) => {
        badgesOptions?.forEach((badge) => {
            if (badge.value === record.badges) {
                if (!badge.activeOnProfile) {
                    console.log("badge is off", badge);
                    disabledTriggerWarning(badge.value);
                }
            }
        });
    };

    const checkForWarnings = (record: ILead, newStatus: number) => {
        console.log("checkForWarnings", newStatus);
        if (record.status !== 1 && record.status !== 4 && (newStatus === 1 || newStatus === 4)) {
            checkIfTriggerIsOn(record);
        }
    };

    const badgesOptionsSorted = useMemo(() => badgesOptions.sort(compareForSort), [badgesOptions]);

    const getColumnSearchProps = (dataIndex: any): ColumnType<ILead> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => {
            const fieldsToUseSelect = ["status", "origin", "badges"];

            if (fieldsToUseSelect.includes(dataIndex)) {
                return (
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Select
                            placeholder={`Search ${dataIndex}`}
                            value={selectedKeys[0]}
                            onChange={(value) => setSelectedKeys(value ? [value] : [])}
                            style={{ marginBottom: 8, display: "block" }}
                        >
                            {dataIndex === "status" &&
                                STATUS_OPTIONS?.map((option) => (
                                    <Select.Option value={option.value?.toString()} key={option.value} color={option.color}>
                                        {option.label}
                                    </Select.Option>
                                ))}
                            {dataIndex === "origin" &&
                                ORIGIN_OPTIONS?.map((option) => (
                                    <Select.Option value={option.value?.toString()} key={option.value} color={option.color}>
                                        {option.label}
                                    </Select.Option>
                                ))}
                            {dataIndex === "badges" &&
                                badgesOptionsSorted?.map((option, index) => (
                                    <Select.Option value={option.value?.toString()} key={index} color={option.color}>
                                        {option.value}
                                    </Select.Option>
                                ))}
                        </Select>
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                                icon={<SearchOutlined />}
                                size="small"
                                style={{ width: 90 }}
                            >
                                Search
                            </Button>
                            <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                                Reset
                            </Button>
                            <Button
                                type="link"
                                size="small"
                                onClick={() => {
                                    close();
                                }}
                            >
                                close
                            </Button>
                        </Space>
                    </div>
                );
            }

            return (
                <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                    <Input
                        ref={searchInputRef}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        style={{ marginBottom: 8, display: "block" }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Reset
                        </Button>
                        <Button
                            type="link"
                            size="small"
                            onClick={() => {
                                close();
                            }}
                        >
                            close
                        </Button>
                    </Space>
                </div>
            );
        },
        filterIcon: (filtered: boolean) => {
            return <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />;
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInputRef.current?.input?.select(), 100);
            }
        },
        render: (text) => (searchedColumn === dataIndex ? <span style={{ color: "#1890ff" }}>{text}</span> : text),
    });

    const [api, contextHolder] = notification.useNotification();
    type NotificationType = "success" | "info" | "warning" | "error";

    const {
        tableProps,
        formProps,
        isEditing,
        setId: setEditId,
        saveButtonProps,
        cancelButtonProps,
        editButtonProps,
        searchFormProps,
    } = useEditableTable<ILead>({
        resource: `campaign/${campaignId}`,
        permanentFilter: [{ field: "_filtered", operator: "eq", value: hidePushedApprovedDenied }],
        errorNotification: (error) => {
            return { message: (error as any).response.data.message, type: "error", description: (error as any).message };
        },
    });

    useEffect(() => {
        if (!campaignId) return;
        getTotalLeadsCount(campaignId);
        getScheduledLeadsCount();
    }, [campaignId, tableProps?.dataSource]);

    const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    };

    async function getBadgesOptions() {
        try {
            const { data } = await axios.get(`${env.REACT_APP_BACKEND_URL}/api/v1/admin_settings/${campaignId}`, config);
            if (data?.length > 0) {
                console.log("data[0]?.badges_options", data[0]?.badges_options);

                return data[0]?.badges_options;
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function getAdminBadges() {
        try {
            const { data } = await axios.get(`${env.REACT_APP_BACKEND_URL}/api/v1/admin_settings`, config);
            if (data?.length > 0) {
                return data[0]?.admin_badges;
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getBadgesOptions().then((data) => {
            setBadgesOptions(data);
        });
        getAdminBadges().then((data) => {
            setAdminBadges(data);
        });
    }, []);

    const checkForDuplicate = (table: any) => {
        const duplicationDetails: IDuplicationDetails[] = [];
        const duplicationDetailsMap: {
            [key: string]: number;
        } = {};

        // check for duplicate and
        table?.dataSource?.forEach((item: ILead) => {
            if (!duplicationDetailsMap[item.url]) {
                duplicationDetailsMap[item.url] = 1;
            } else {
                duplicationDetailsMap[item.url] += 1;
            }
        });

        Object.keys(duplicationDetailsMap).forEach((key) => {
            if (duplicationDetailsMap[key] > 1) {
                // add DUPLICATE tag to the lead to admin_note
                // find duplicate lead and add DUPLICATE tag to admin_note
                const DUPLICATE_TAG = "DUPLICATE";
                table?.dataSource?.forEach((item: ILead) => {
                    if (item.url === key) {
                        // add to duplicate array
                        setDuplicateArray((duplicateArray) => new Set([...duplicateArray, item.id]));
                    }
                });
            }
        });
    };

    const checkForBlacklisted = async (userSettings: any, table: any) => {
        console.log("checkForBlacklisted");

        if (!userSettings?.company_blacklist) return;

        const blacklistCompanies: Set<string> = userSettings?.company_blacklist;

        console.log("blacklistCompanies", blacklistCompanies);

        const BLACKLISTED_TAG = "BLACKLIST";
        table?.dataSource?.forEach((item: ILead) => {
            // normalize company name and blacklist company name
            const normalizedCompanyName = item?.company_name?.replace(/\s/g, "")?.toLowerCase();

            console.log("normalizedCompanyName", normalizedCompanyName);

            if (blacklistCompanies?.has(normalizedCompanyName)) {
                // add to blacklist array
                console.log("blacklisted", item.id);

                setBlackListArray((prev) => new Set([...prev, item.id]));
            }
        });
    };

    const captureConflictEvent = (record: ILead) => {
        //  check for conflicts
        const isBlacklisted = blackListArray.has(record.id);
        const isDuplicate = duplicateArray.has(record.id);
        const isTriggerOn = badgesOptions.find((badge) => badge.value === record.badges)?.activeOnProfile;

        const conflicts = [];

        if (isBlacklisted) {
            conflicts.push("COMPANY BLACKLISTED");
        }

        if (isDuplicate) {
            conflicts.push("ALREADY EXISTS");
        }

        if (!isTriggerOn) {
            conflicts.push("TRIGGER OFF FOR PROFILE");
        }

        posthog.capture("Pushed conflicted prospect", {
            prospect_id: record.id,
            conflicts: conflicts,
        });
    };

    const changeTableValues = (changes: any) => {
        tableProps?.dataSource?.forEach((item) => {
            if (changes[item.id]) {
                axios
                    .patch(`${env.REACT_APP_BACKEND_URL}/api/v1/campaign/${campaignId}/${item.id}`, changes[item.id], {
                        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                    })
                    .then((response) => {
                        console.log(response);
                        for (const key in changes[item.id]) {
                            switch (key) {
                                case "campaign_id": {
                                    if (item.exported === 1) {
                                        openNotificationWithIcon("error", `Cannot change campaign of exported lead ${item.id}`);
                                        return;
                                    }
                                    item[key] = changes[item.id][key];
                                    break;
                                }
                                default: {
                                    item[key] = changes[item.id][key];
                                }
                            }
                            console.log({ item });
                        }
                        openNotificationWithIcon("success", `Updated successfully ${item.id}`);
                        getScheduledLeadsCount();
                    })
                    .catch((error) => {
                        openNotificationWithIcon("error", error.response.data.message);
                    });
            }
        });
        setCheckedRows({});
    };

    useEffect(() => {
        if (duplicateArray.size > 0 || blackListArray.size > 0) {
            return;
        }
        if (!userSettings) {
            setDuplicateArray(new Set());
            setBlackListArray(new Set());
            getUserSettings(campaignId).then((data) => {
                setUserSettings(data);
                checkForDuplicate(tableProps);
                checkForBlacklisted(data, tableProps);
                console.log("duplicateArray", duplicateArray);
                console.log("blackListArray", blackListArray);
                console.log("userSettings", data);
                console.log("userSettingsTimezone", data.timezone);
            });
        } else {
            checkForDuplicate(tableProps);
            checkForBlacklisted(userSettings, tableProps);
        }
    }, [tableProps.dataSource]);

    useEffect(() => {
        if (!tableProps.loading) {
            console.log("tableProps.loading", tableProps.loading);
            console.log("tableProps.loading", tableProps);

            checkForBlacklisted(userSettings, tableProps);
        }
    }, [tableProps.loading]);

    const openNotificationWithIcon = (type: NotificationType, message: string) => {
        api[type]({
            style: { whiteSpace: "pre-line", zIndex: 9999 },
            message: type,
            description: message,
        });
    };

    const ExpandModal = React.memo(function ExpandModal(props) {
        const { value, column, title, id, templates } = modalData;

        // Fetch from local storage
        const storedFields = localStorage.getItem("fieldsForTemplate");
        const initialFields = storedFields ? JSON.parse(storedFields) : null;

        const [tempValue, setTempValue] = useState(value);
        const [contentValue, setContentValue] = useState("");
        const [fieldsForTemplate, setFieldsForTemplate] = useState<any>(initialFields);

        // Save to local storage when fieldsForTemplate changes
        useEffect(() => {
            if (fieldsForTemplate) {
                localStorage.setItem("fieldsForTemplate", JSON.stringify(fieldsForTemplate));
            }
        }, [fieldsForTemplate]);

        useEffect(() => {
            console.log("ExpandModal rendered!");
        });

        useEffect(() => {
            console.log("chosenTemplate CHANGED TO:", chosenTemplate);
        }, [chosenTemplate]);

        const generateMessage = async (template: any) => {
            console.log("generateMessage -> chosenTemplate:", chosenTemplate);
            console.log("generateMessage -> template:", template);
            console.log("generateMessage -> tempValue:", tempValue);
            console.log("generateMessage -> contentValue:", contentValue);
            console.log("generateMessage -> fieldsForTemplate:", fieldsForTemplate);
            // dont generate if required fields are not filled
            if (loadingMessageGeneration) {
                message.error("Please wait for the message to be generated");
                return;
            }
            if (fieldsForTemplate) {
                const requiredFields = template?.required_fields;
                console.log("requiredFields", requiredFields);
                const fieldsForTemplateKeys = Object.keys(fieldsForTemplate);
                console.log("fieldsForTemplateKeys", fieldsForTemplateKeys);
                let missingFields = [];

                if (Array.isArray(requiredFields)) {
                    // Original logic for array
                    missingFields = requiredFields.filter((field: any) => !fieldsForTemplateKeys.includes(field));
                } else {
                    // New logic for object
                    missingFields = Object.keys(requiredFields).filter((field: any) => !fieldsForTemplateKeys.includes(field));
                }

                if (missingFields.length > 0) {
                    message.error(`Please fill the required fields: ${missingFields.join(", ")}`);
                    return;
                }
            }

            setLoadingMessageGeneration(true);
            try {
                // send request to generate message by template type
                switch (template?.type) {
                    case 0:
                        message.error("Cannot generate message for hard template");
                        break;
                    case 1:
                        await generateForWorkflowTemplate(template);
                        break;
                    case 2:
                        await generateForSoftTemplate(template);
                        break;
                }
            } catch (error: any) {
                console.log(error);
                message.error("Error generating message");
            } finally {
                setLoadingMessageGeneration(false);
            }
        };

        const generateForSoftTemplate = async (template: any) => {
            const { data } = await axios.post(
                `${env.REACT_APP_BACKEND_URL}/api/v1/campaign/generate_message`,
                { template: chosenTemplate, message: tempValue, content: contentValue, fields: fieldsForTemplate },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                }
            );
            console.log("data", data);
            if (data?.message) {
                setGeneratedMessage(data.message);
            }
        };

        const generateForWorkflowTemplate = async (template: any) => {
            const { data } = await axios.post(
                `${env.REACT_APP_BACKEND_URL}/api/v1/templates/generate_message`,
                {
                    workflow_name: template?.workflow_function,
                    template_vars: fieldsForTemplate,
                    template_string: template?.message,
                },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                }
            );

            console.log("data", data);
            if (data?.message) {
                setGeneratedMessage(data.message);
            }
        };

        const handleHardTemplateChange = (template: any) => {
            const { message, message_2, summary } = template;
            const lead = tableProps.dataSource?.find((item) => item.id === id);

            const first_name = lead?.name?.split(" ")[0];
            const last_name = lead?.name?.split(" ")[1];
            const company_name = lead?.company_name;

            const newMessage = message.replace("{first_name}", first_name).replace("{last_name}", last_name).replace("{company_name}", company_name);

            setTempValue(newMessage);
            setModalData({ ...modalData, value: newMessage });
            setContentInputFill(false);
            console.log("Setting chosen template to:", template);
        };

        const handleSoftTemplateChange = (template: any) => {
            const { message, message_2, summary } = template;

            const lead = tableProps.dataSource?.find((item) => item.id === id);

            const first_name = lead?.name?.split(" ")[0];
            const last_name = lead?.name?.split(" ")[1];
            const company_name = lead?.company_name;

            const newMessage = message.replace("{first_name}", first_name).replace("{last_name}", last_name).replace("{company_name}", company_name);

            setTempValue(newMessage);
            setModalData({ ...modalData, value: newMessage });
            setContentInputFill(true);

            console.log("contentInputFill", contentInputFill);
            console.log("Setting chosen template to:", template);
        };

        const handleWorkflowTemplateChange = async (template: any) => {
            console.log("handleWorkflowTemplateChange -> template", template);
            const { message, message_2, summary } = template;

            const lead = tableProps.dataSource?.find((item) => item.id === id);

            console.log("handleWorkflowTemplateChange -> selectedTemplate", template);

            try {
                const response = await axios.get(`${env.REACT_APP_BACKEND_URL}/api/v1/templates/workflow`, {
                    params: {
                        workflow_name: template?.workflow_function,
                    },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });

                console.log("handleWorkflowTemplateChange -> response", response);

                const requiredFields = response?.data?.required_fields;

                console.log("handleWorkflowTemplateChange -> requiredFields", requiredFields);

                // set chosen template to the template but with the required fields, but no content input
                setChosenTemplate({ ...template, required_fields: requiredFields });
                setContentInputFill(false);
                setFieldsForTemplate(null);
            } catch (error) {
                console.error("Error fetching required fields:", error);
                message.error("Error fetching required fields");
            }
        };

        const handleTemplateChange = (value: string, templates: any) => {
            console.log("handleTemplateChange -> value", value);

            const template = templates.find((item: any) => item.type === value);
            if (template) {
                const templateType = template?.type;

                console.log("templateType", templateType);
                console.log("template", template);
                console.log("chosenTemplate", chosenTemplate);

                switch (templateType) {
                    case 0:
                        handleHardTemplateChange(template);
                        break;
                    case 1:
                        handleWorkflowTemplateChange(template);
                        break;
                    case 2:
                        handleSoftTemplateChange(template);
                        break;
                }
            } else {
                setTempValue("");
            }
        };

        const handleModalClick = (e: React.MouseEvent) => {
            e.stopPropagation();
        };

        return (
            <div onClick={handleModalClick}>
                <Modal
                    title={title}
                    open={isModalVisible}
                    maskClosable={false}
                    destroyOnClose={false}
                    onOk={() => {
                        setIsModalVisible(false);
                        tableProps.dataSource?.find((item) => {
                            if (item.id === id) {
                                item[column] = tempValue;
                                return true;
                            }
                            return false;
                        });
                        // send request to update submit with lead id and the new key and value
                        axios
                            .patch(
                                `${env.REACT_APP_BACKEND_URL}/api/v1/campaign/${campaignId}/${id}`,
                                { [column]: tempValue },
                                {
                                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                                }
                            )
                            .then((response) => {
                                console.log(response);
                                openNotificationWithIcon("success", "Updated successfully");
                            })
                            .catch((error) => {
                                console.log(error);
                                openNotificationWithIcon("error", "Something went wrong");
                            });
                    }}
                    onCancel={() => {
                        // reset content and fields
                        // setChosenTemplate(null);
                        // setTempValue("");
                        // setContentValue("");
                        // setFieldsForTemplate(null);
                        // setGeneratedMessage("");
                        // setContentInputFill(false);
                        setIsModalVisible(false);
                    }}
                >
                    {templates?.length > 0 && (
                        <Select
                            style={{ width: "100%", margin: "10px 0" }}
                            placeholder="Select template"
                            onChange={(id) => {
                                // Find the selected template using the id
                                const selectedTemplate = templates.find((template: any) => template.id === id);
                                setChosenTemplate(selectedTemplate);
                                handleTemplateChange(selectedTemplate?.type, templates);
                            }}
                        >
                            {templates.map((item: any) => {
                                // decide class based on type
                                let itemClass, hoverText;
                                switch (item.type) {
                                    case 0:
                                        itemClass = "hard";
                                        hoverText = "Only Template";
                                        break;
                                    case 1:
                                        itemClass = "link";
                                        hoverText = "Workflow Template";
                                        break;
                                    case 2:
                                        itemClass = "soft";
                                        hoverText = "Paste Content + Template";
                                        break;
                                    default:
                                        itemClass = "default";
                                        hoverText = "Default";
                                        break;
                                }

                                return (
                                    <Select.Option
                                        style={{
                                            width: "100%",
                                            margin: "10px 0",
                                        }}
                                        key={item?.id}
                                        value={item?.id}
                                    >
                                        <Tooltip
                                            title={
                                                <div>
                                                    <span className={`color-dot-${itemClass}`}></span>
                                                    {hoverText}
                                                </div>
                                            }
                                        >
                                            <span className={`color-dot-${itemClass}`}></span>
                                        </Tooltip>
                                        {item?.message}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    )}

                    {
                        <>
                            {contentInputFill && (
                                <div>
                                    <strong>
                                        <label htmlFor="content">Content</label>
                                    </strong>
                                    <TextareaAutosize
                                        // @ts-ignore
                                        style={{ minWidth: "100%", height: "300px" }}
                                        onChange={(e) => {
                                            setContentValue(e.target.value);
                                        }}
                                        placeholder={"Fill Relevant Content (Post, Event, etc)"}
                                    />
                                </div>
                            )}
                            <strong>
                                <label htmlFor="fields">Required Fields for this template:</label>
                            </strong>
                            {
                                // Check if 'required_fields' is an array
                                Array.isArray(chosenTemplate?.required_fields)
                                    ? // Handle array of strings as before
                                      chosenTemplate?.required_fields?.map((field: string) => (
                                          <div key={field}>
                                              <label htmlFor={field}>{field}</label>
                                              <Input
                                                  style={{ width: "100%" }}
                                                  onChange={(e) => {
                                                      setFieldsForTemplate((prev: any) => ({ ...prev, [field]: e.target.value }));
                                                  }}
                                                  placeholder={`Fill ${field}`}
                                              />
                                          </div>
                                      ))
                                    : // Handle object of fields with data type
                                      Object.entries(chosenTemplate?.required_fields || {}).map(([field, type]) => {
                                          let InputComponent;

                                          switch (type) {
                                              case "string":
                                                  InputComponent = (
                                                      <Input
                                                          value={fieldsForTemplate?.[field] || ""}
                                                          style={{ width: "100%" }}
                                                          onChange={(e) => {
                                                              setFieldsForTemplate((prev: any) => ({ ...prev, [field]: e.target.value }));
                                                          }}
                                                          placeholder={`Fill ${field}`}
                                                      />
                                                  );
                                                  break;

                                              case "date":
                                                  InputComponent = (
                                                      <DatePicker
                                                          style={{ width: "100%" }}
                                                          format="DD/MM/YY"
                                                          inputReadOnly={true}
                                                          onChange={(date: any, dateString: string) => {
                                                              setFieldsForTemplate((prev: any) => ({ ...prev, [field]: dateString }));
                                                          }}
                                                          placeholder={`Select ${field}`}
                                                      />
                                                  );
                                                  break;

                                              default:
                                                  InputComponent = (
                                                      <Input
                                                          style={{ width: "100%" }}
                                                          onChange={(e) => {
                                                              setFieldsForTemplate((prev: any) => ({ ...prev, [field]: e.target.value }));
                                                          }}
                                                          placeholder={`Fill ${field}`}
                                                      />
                                                  );
                                                  break;
                                          }

                                          return (
                                              <div key={field}>
                                                  <label htmlFor={field}>{field}</label>
                                                  {InputComponent}
                                              </div>
                                          );
                                      })
                            }
                        </>
                    }
                    <strong>
                        <label htmlFor="message">Message</label>
                    </strong>
                    <TextareaAutosize
                        // @ts-ignore
                        style={{ minWidth: "100%", height: "300px" }}
                        onChange={(e) => {
                            setTempValue(e.target.value);
                        }}
                        defaultValue={value}
                        placeholder={"Input message here"}
                    />
                    {
                        // if chosen template is hard or soft, show generate message button
                        (chosenTemplate?.type === 1 || chosenTemplate?.type === 2) && (
                            <div>
                                <Button
                                    style={{ margin: "10px 0" }}
                                    onClick={() => {
                                        setGeneratedMessage("");
                                        generateMessage(chosenTemplate);
                                    }}
                                >
                                    {loadingMessageGeneration ? (
                                        <Spin
                                            indicator={antIcon}
                                            style={{
                                                width: "100px",
                                                transition: "all 0.3s ease",
                                            }}
                                        />
                                    ) : generatedMessage ? (
                                        <span
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                gap: "10px",
                                            }}
                                        >
                                            <RedoOutlined />
                                            Generate again
                                        </span>
                                    ) : (
                                        "Generate Message"
                                    )}
                                </Button>
                            </div>
                        )
                    }
                    {generatedMessage && (
                        <div>
                            <Card title="Generated Message">
                                <Alert
                                    message={
                                        <span>
                                            Please <strong>carefully</strong> check the generated message for any mistakes.
                                        </span>
                                    }
                                    type="warning"
                                    showIcon
                                    closable
                                />

                                <p style={{ margin: "10px 0" }}>{generatedMessage}</p>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        margin: "10px 0",
                                        gap: "10px",
                                    }}
                                >
                                    <Button
                                        style={{ margin: "10px 0" }}
                                        onClick={() => {
                                            setTempValue(generatedMessage);
                                            setModalData({ ...modalData, value: generatedMessage });
                                            setGeneratedMessage("");
                                        }}
                                    >
                                        Move to editor
                                    </Button>
                                    <Button
                                        style={{ margin: "10px 0" }}
                                        onClick={() => {
                                            setGeneratedMessage("");
                                        }}
                                    >
                                        Clear
                                    </Button>
                                </div>
                            </Card>
                        </div>
                    )}
                </Modal>
            </div>
        );
    });

    const { Meta } = Card;

    return (
        <List>
            <div>
                <div style={{ marginBottom: "12px" }}>
                    <Row gutter={4}>
                        {!!nextPushTime.localTime && (
                            <Col span={4.5}>
                                <Card style={{ width: "230px" }}>
                                    <Statistic
                                        title="Next scheduled push time"
                                        value={nextPushTime.localTime}
                                        prefix={<ClockCircleOutlined />}
                                        style={{ marginBottom: "8px" }}
                                    />
                                    <Meta description={`(${nextPushTime.customerTime} in customer's time)`} />
                                </Card>
                            </Col>
                        )}
                        <Col span={4.5}>
                            <Card style={{ width: "220px" }}>
                                <Statistic
                                    title="# of scheduled prospects"
                                    value={scheduledLeadsCount}
                                    suffix={"/ 30"}
                                    style={{ marginBottom: "8px" }}
                                />
                                <Meta description={`(for selected profile)`} />
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        position: "absolute",
                        top: "0",
                    }}
                >
                    <div style={{ fontSize: "25px", fontWeight: "bold" }}>{campaignData?.name}</div>
                    <div
                        style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            color: campaignData?.active ? "green" : "red",
                        }}
                    >
                        {campaignData?.active ? "Active" : campaignData?.active === 0 ? "Not Active" : ""}
                    </div>
                </div>
                <ExpandModal />
                <Space>
                    {editable && <CreateNewLeadForm badgesOptions={badgesOptions} />}
                    {editable && <ChangeBulk checkedRows={checkedRows} changeTableValues={changeTableValues} adminBadges={adminBadges} />}
                    {editable && (
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                setCheckedRows({});
                            }}
                        >
                            Unselect all
                        </Button>
                    )}
                    {editable && <MoveToCampaign checkedRows={checkedRows} changeTableValues={changeTableValues} />}
                    <Button
                        onClick={() => {
                            setExpandAll((prev) => !prev);
                        }}
                    >
                        {expandAll ? "Compress all messages" : "Expand all messages"}
                    </Button>
                    <Button
                        onClick={() => {
                            setHidePushedApprovedDenied((prev) => !prev);
                        }}
                    >
                        {hidePushedApprovedDenied ? "Show all status" : "Show only scheduled and hidden"}
                    </Button>
                </Space>
                {contextHolder}
                <Form {...formProps} initialValues={[]}>
                    {/* <ExpandModal /> */}
                    <Table
                        {...tableProps}
                        // on render check for blacklisted
                        sticky={true}
                        scroll={{ x: TABLE_COLUMNS.length * 150 }}
                        // rowClassName={(record) => (checkedRows[record.id] ? "selected-color" : "")}
                        rowClassName={(record) => {
                            console.log("record", record);
                            if (checkedRows[record.id]) {
                                return "selected-color";
                            } else if (record?.funnel_status === "MONITORED") {
                                return "monitored-color";
                            } else return "";
                        }}
                        rowKey="id"
                        pagination={{
                            total: totalLeadsCount,
                            showSizeChanger: true,
                            pageSizeOptions: ["5", "10", "20", "50", "100", "500", "1000", "5000"],
                            defaultPageSize: 50,
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        }}
                        rowSelection={{
                            selectedRowKeys: Object.keys(checkedRows).filter((key) => checkedRows[key]),
                            onChange: (selectedRowKeys) => {
                                const newCheckedRows: any = {};
                                selectedRowKeys.forEach((key) => {
                                    newCheckedRows[key] = true;
                                });
                                setCheckedRows(newCheckedRows);
                            },
                        }}
                        onRow={(record) => ({
                            // eslint-disable-next-line
                            onClick: (event: any) => {
                                if (editable && (event.target.tagName === "TD" || event.target.parentNode.tagName === "TD")) {
                                    setEditId && setEditId(record.id);
                                }
                            },
                        })}
                    >
                        {TABLE_COLUMNS.map((column) => (
                            <Table.Column<ILead>
                                // insert getSearchProps here
                                {...getColumnSearchProps(column.dataIndex)}
                                key={column.dataIndex}
                                dataIndex={column.dataIndex}
                                title={column.title}
                                width={
                                    (() => {
                                        switch (column.dataIndex) {
                                            case "id":
                                                return 100;
                                            case "recommendation_score":
                                                return 100;
                                            case "badges":
                                                return 300;
                                            case "admin_note":
                                                return 300;
                                            case "scoring_reason":
                                                return 600;
                                            case "company_headquarters":
                                                return 200;
                                        }
                                    })() as number
                                }
                                sorter={(a, b) => {
                                    if (column.dataIndex === "badges") {
                                        return a.badges.length - b.badges.length;
                                    }
                                    if (column.dataIndex === "exported") {
                                        // only sort by exported_at if exported is true
                                        if (a.exported && b.exported) {
                                            return a.exported_at > b.exported_at;
                                        }
                                    }
                                    if (column.dataIndex === "status") {
                                        return a.status > b.status;
                                    }
                                    if (column.dataIndex === "origin") {
                                        return a.origin.localeCompare(b.origin);
                                    }
                                    return a[column.dataIndex]?.toString().localeCompare(b[column.dataIndex]?.toString());
                                }}
                                sortDirections={["ascend", "descend"]}
                                defaultSortOrder={column.dataIndex === "updated_at" ? "descend" : undefined}
                                render={(value, record) => {
                                    if (column.dataIndex === "message" && expandAll) {
                                        if (true) {
                                            return (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "10px",
                                                    }}
                                                >
                                                    <MessageVerification
                                                        invokeVerification={null}
                                                        message={value}
                                                        record={record}
                                                        isEditorOpen={isModalVisible}
                                                        verifiedCallback={() => null}
                                                        failedCallback={() => null}
                                                        handleAcceptSuggestion={(suggestion: string) => {
                                                            axios
                                                                .patch(
                                                                    `${env.REACT_APP_BACKEND_URL}/api/v1/campaign/${campaignId}/${record?.id}`,
                                                                    { [column.dataIndex]: suggestion },
                                                                    {
                                                                        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                                                                    }
                                                                )
                                                                .then((response) => {
                                                                    console.log(response);
                                                                    openNotificationWithIcon("success", "Updated successfully");
                                                                    // update locally
                                                                    tableProps.dataSource?.find((item) => {
                                                                        if (item.id === record.id) {
                                                                            item[column.dataIndex] = suggestion;
                                                                            return true;
                                                                        }
                                                                        return false;
                                                                    });
                                                                })
                                                                .catch((error) => {
                                                                    console.log(error);
                                                                    openNotificationWithIcon("error", "Something went wrong");
                                                                });

                                                            // set message_checked to 1
                                                            axios
                                                                .patch(
                                                                    `${env.REACT_APP_BACKEND_URL}/api/v1/campaign/${campaignId}/${record?.id}`,
                                                                    { message_checked: 1 },
                                                                    {
                                                                        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                                                                    }
                                                                )
                                                                .then((response) => {
                                                                    console.log(response);
                                                                    // openNotificationWithIcon("success", "Used Suggestion successfully");
                                                                    // refresh table
                                                                    window.location.reload();
                                                                })
                                                                .catch((error) => {
                                                                    console.log(error);
                                                                    // openNotificationWithIcon("error", "Something went wrong");
                                                                });
                                                        }}
                                                    />
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: "10px",
                                                        }}
                                                    >
                                                        {value}
                                                        <Button
                                                            onClick={() => {
                                                                navigate(`/campaign/edit/${record.id}`);
                                                            }}
                                                        >
                                                            Edit Message
                                                        </Button>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    }
                                    if (column.dataIndex === "message_2" && expandAll) {
                                        return (
                                            <Form.Item>
                                                <div
                                                    style={{
                                                        minWidth: 200,
                                                        minHeight: 50,
                                                    }}
                                                    onClick={() => {
                                                        setIsModalVisible(true);
                                                        setModalData({
                                                            title: column.title,
                                                            value: value,
                                                            column: column.dataIndex,
                                                            id: record.id,
                                                            templates: [],
                                                        });
                                                    }}
                                                >
                                                    {value}
                                                </div>
                                            </Form.Item>
                                        );
                                    }
                                    if (column.dataIndex in { message: "", message_2: "", summary: "" }) {
                                        return (
                                            <Form.Item>
                                                <div
                                                    style={{
                                                        minWidth: 50,
                                                        minHeight: 50,
                                                    }}
                                                    onClick={() => {
                                                        setIsModalVisible(true);
                                                        setModalData({
                                                            title: column.title,
                                                            value: value,
                                                            column: column.dataIndex,
                                                            id: record.id,
                                                            templates: [],
                                                        });
                                                    }}
                                                >
                                                    {value?.length > 20 ? value?.toString().substring(0, 20) + "..." : value}
                                                </div>
                                            </Form.Item>
                                        );
                                    }
                                    if (isEditing(record.id)) {
                                        switch (column.dataIndex) {
                                            case "status":
                                                const options =
                                                    campaignData?.name === "Automated Trigger Bucket"
                                                        ? AUTOMATED_TRIGGER_BUCKET_STATUS_OPTIONS
                                                        : STATUS_OPTIONS;

                                                return (
                                                    <Form.Item name={column.dataIndex} style={{ margin: 0 }} initialValue={value}>
                                                        <Select style={{ width: 120 }}>
                                                            {options.map((option) => (
                                                                <Select.Option value={option.value} key={option.value} color={option.color}>
                                                                    <div onClick={() => checkForWarnings(record, option.value)}>{option.label}</div>
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                );
                                            case "exported":
                                                // return  yes or no tag based on value
                                                return (
                                                    <Form.Item name={column.dataIndex} style={{ margin: 0 }} initialValue={value}>
                                                        <Select style={{ width: 120 }}>
                                                            <Select.Option value={1} key={1} color="green">
                                                                Yes
                                                            </Select.Option>
                                                            <Select.Option value={0} key={0} color="red">
                                                                No
                                                            </Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                );
                                            case "origin":
                                                return (
                                                    <Form.Item name={column.dataIndex} style={{ margin: 0 }} initialValue={value}>
                                                        <Select style={{ width: 120 }}>
                                                            {ORIGIN_OPTIONS?.map((option) => (
                                                                <Select.Option value={option.value} key={option.value} color={option.color}>
                                                                    {option.label}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                );
                                            case "badges":
                                                const badges = value?.split(",");
                                                return (
                                                    <Form.Item name={column.dataIndex} style={{ margin: 0 }} initialValue={badges}>
                                                        <Select mode="tags" style={{ width: "100%" }} tokenSeparators={[","]}>
                                                            {badgesOptions?.map((option) => (
                                                                <Select.Option value={option.value} key={option.value} color={option.color}>
                                                                    {option.value}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                );
                                            case "badges_links":
                                                const badges_links = value?.split(",");
                                                return (
                                                    <Form.Item name={column.dataIndex} style={{ margin: 0 }} initialValue={badges_links}>
                                                        <Select mode="tags" style={{ width: "100%" }} tokenSeparators={[","]}>
                                                            {badges_links?.map((link: string, index: number) => (
                                                                <Select.Option key={link}>{link}</Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                );

                                            case "admin_note":
                                                if (loadingTempNotes) {
                                                    return <Spin indicator={antIcon} />;
                                                }

                                                return (
                                                    <Form.Item name={column.dataIndex} style={{ margin: 0 }} initialValue={value}>
                                                        <TextareaAutosize
                                                            style={{
                                                                background: "#FFFFFF",
                                                                border: "1px solid #7A7A7A",
                                                                borderRadius: "6px",
                                                                width: "280px",
                                                                whiteSpace: "pre-wrap",
                                                                resize: "none",
                                                                overflow: "hidden",
                                                            }}
                                                        />
                                                    </Form.Item>
                                                );
                                            case "automated_trigger":
                                                console.log("automated_trigger", value);
                                                return (
                                                    <Tag style={{ margin: "2px" }} color={value ? "green" : "red"}>
                                                        {value ? "Automated" : "Manual"}
                                                    </Tag>
                                                );
                                        }

                                        if (UNEDITABLE_COLUMNS.includes(column.dataIndex)) {
                                            switch (column.dataIndex) {
                                                case "id":
                                                    return <TextField value={value} />;
                                                case "created_at":
                                                case "updated_at":
                                                    return <>{moment(value).format("DD/MM/YY")}</>;

                                                default:
                                                    return <>{value}</>;
                                            }
                                        }
                                        return (
                                            <Form.Item name={column.dataIndex} style={{ margin: 0 }} initialValue={value}>
                                                <TextareaAutosize
                                                    style={{
                                                        background: "#FFFFFF",
                                                        border: "1px solid #7A7A7A",
                                                        borderRadius: "6px",
                                                        width: "100%",
                                                        whiteSpace: "pre-wrap",
                                                        resize: "none",
                                                        overflow: "hidden",
                                                    }}
                                                />
                                            </Form.Item>
                                        );
                                    } else {
                                        switch (column.dataIndex) {
                                            case "status":
                                                return (
                                                    <TagField
                                                        color={STATUS_OPTIONS?.find((option) => option.value === value)?.color}
                                                        value={STATUS_OPTIONS?.find((option) => option.value === value)?.label}
                                                    />
                                                );
                                            case "exported":
                                                return (
                                                    <TagField
                                                        color={value ? "green" : "red"}
                                                        value={value ? `Yes (${record?.exported_at?.replace("T", " ").slice(0, -8)})` : "No"}
                                                    />
                                                );
                                            case "origin":
                                                return (
                                                    <TagField
                                                        color={ORIGIN_OPTIONS?.find((option) => option.value === value)?.color}
                                                        value={ORIGIN_OPTIONS?.find((option) => option.value === value)?.label}
                                                    />
                                                );
                                            case "badges":
                                                const badges: string[] = value?.split(",");
                                                return badges?.map((badge: string, index: number) => {
                                                    return (
                                                        <TagField
                                                            style={{ margin: "2px" }}
                                                            key={index}
                                                            color={badgesOptions?.find((option) => option.value === badge.toUpperCase())?.color}
                                                            value={badgesOptions?.find((option) => option.value === badge.toUpperCase())?.value}
                                                        />
                                                    );
                                                });
                                            case "admin_note":
                                                if (loadingTempNotes) {
                                                    return <Spin indicator={antIcon} />;
                                                }

                                                if (typeof value !== "string") return null;

                                                return <div>{value}</div>;
                                            case "auto_note":
                                                return (
                                                    <>
                                                        {duplicateArray.has(record.id) && (
                                                            <TagField style={{ margin: "2px" }} color={"#ff9b05"} value={"DUPLICATE"} />
                                                        )}
                                                        {blackListArray.has(record.id) && (
                                                            <TagField style={{ margin: "2px" }} color={"black"} value={"BLACKLISTED"} />
                                                        )}
                                                    </>
                                                );
                                            case "scoring_reason":
                                                return (
                                                    // antd card thats collapsed by default
                                                    <Collapse
                                                        style={{
                                                            minWidth: "500px",
                                                            maxWidth: "500px",
                                                            maxHeight: "500px",
                                                            overflow: "auto",
                                                        }}
                                                        defaultActiveKey={["1"]}
                                                    >
                                                        <Collapse.Panel header="Show Scoring Reason" key="1">
                                                            <p>{value}</p>
                                                        </Collapse.Panel>
                                                    </Collapse>
                                                );
                                            case "recommendation_score":
                                                const getShortSummaryTitle = (score: number) => {
                                                    let title;

                                                    return <strong>{score}</strong>;
                                                };

                                                const getShortSummaryType = (score: number) => {
                                                    switch (true) {
                                                        case score > 7:
                                                            return "success";
                                                        case score <= 5:
                                                            return "error";
                                                        default:
                                                            return "warning";
                                                    }
                                                };

                                                return (
                                                    <Alert
                                                        message={getShortSummaryTitle(record.recommendation_score)}
                                                        type={getShortSummaryType(record.recommendation_score)}
                                                        showIcon
                                                    />
                                                );

                                            case "badges_links":
                                                const badges_links: string[] = value?.split(",");
                                                return badges_links?.map((link: string, index: number) => {
                                                    return (
                                                        <a href={link} target="_blank">
                                                            <TagField
                                                                style={{ margin: "2px" }}
                                                                key={index}
                                                                color={"green"}
                                                                value={`LINK${index + 1}`}
                                                            />
                                                        </a>
                                                    );
                                                });
                                            case "id":
                                                return (
                                                    <>
                                                        <TextField value={"ID"} />
                                                        {record?.funnel_status === "MONITORED" ? (
                                                            <div className="monitored-label-color">(MONITORED)</div>
                                                        ) : null}
                                                    </>
                                                );
                                            case "updated_at":
                                            case "created_at":
                                                return <TextField value={moment(value).format("DD/MM/YY")} />;
                                            case "url":
                                                return (
                                                    <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        href={value.includes("https://") ? value : "https://" + value}
                                                    >
                                                        View Profile
                                                    </a>
                                                );
                                            case "email":
                                                if (value) {
                                                    return value;
                                                }
                                                return <GetEmailButton lead={record} />;
                                            case "automated_trigger":
                                                console.log("automated_trigger", value);
                                                return (
                                                    <Tag style={{ margin: "2px" }} color={value ? "green" : "red"}>
                                                        {value ? "Automated" : "Manual"}
                                                    </Tag>
                                                );

                                            default:
                                                return <TextField value={value} />;
                                        }
                                    }
                                }}
                            />
                        ))}

                        {editable && (
                            <Table.Column<ILead>
                                title="Actions"
                                dataIndex="actions"
                                render={(_, record) => {
                                    if (isEditing(record.id)) {
                                        return (
                                            <Space direction="vertical">
                                                <Button {...cancelButtonProps} size="small">
                                                    Cancel
                                                </Button>
                                                <SaveButton
                                                    onSubmit={() => {
                                                        captureConflictEvent(record);
                                                    }}
                                                    {...saveButtonProps}
                                                    onError={(e) => {
                                                        console.log("save error", e);
                                                    }}
                                                    size="small"
                                                >
                                                    Save
                                                </SaveButton>
                                                {campaignId && (
                                                    <DeleteButton
                                                        resourceNameOrRouteName={`api/v1/campaign/${campaignId}`}
                                                        size="small"
                                                        recordItemId={record.id}
                                                    >
                                                        Delete
                                                    </DeleteButton>
                                                )}
                                            </Space>
                                        );
                                    }
                                    return (
                                        <Space>
                                            <EditButton {...editButtonProps(record.id)} hideText size="small" />
                                        </Space>
                                    );
                                }}
                            />
                        )}
                    </Table>
                </Form>
            </div>
        </List>
    );
};
